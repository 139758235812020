<template>
    <div class="right-cell container-pc">
        <div class="cell-view">
            <div class="cell-title">BRAND CONCEPT</div>
            <div class="cell-img">
                <img src="../../../assets/images/image1.png" alt="">
            </div>
            <div class="cell-spant">
                ROSEONLY, established on January 4, 2013, a date symbolizing "loving you for a
                lifetime", was born from a simple yet profound question: Do you still believe in true love?
            </div>
            <div class="cell-img-view m-20">
                <img class="cell-left" src="../../../assets/images/image2.png" alt="">
                <div class="cell-right">
                    In an age where noisy distractions have diluted authentic emotions, and skepticism about true love
                    runs rampant, loving one person for a lifetime seems like a distant myth. We chose the rose, a
                    classic symbol of true love, to represent our belief. We refuse to accept that fleeting
                    temporalities can overshadow the eternity of love. With the rose as our emblem, we are committed to
                    the promise of "loving one person for a lifetime."
                </div>
            </div>

            <div class="cell-title">ABOUT ROSEONLY</div>
            <div class="cell-img">
                <img src="../../../assets/images/image3.png" alt="">
            </div>
            <div class="cell-spant">"Love exists for those who have faith in it; love is all you need."
                <br />
                ROSEONLY, dedicated to spreading the message of love, envisions itself as a premium
                brand of true love embodied in romantic gifts, leading the way in customized luxury rose bouquets.
            </div>


            <div class="cell-img-view m-20">
                <img class="cell-left" src="../../../assets/images/image11.png" alt="">
                <div class="cell-right">
                    Since
                    its inception, the brand has visited top rose gardens worldwide: from the sun-drenched Bulgarian
                    rose
                    gardens of the Mediterranean to Egypt along the Nile, rich in spices. From the mysterious rose
                    garden at
                    Hemingway's Key West home to the renowned distilled rose water region of Kashan, Iran. Finally, we
                    settled on the extraordinary roses near the equator in Ecuador.
                </div>
            </div>
            <div class="cell-img m-20">
                <img src="../../../assets/images/image4.png" alt="">
            </div>
            <div class="cell-title">5 GIFT CATEGORIES</div>
            <div class="cell-spant">ROSEONLY, with its unparalleled Ecuadorian roses, introduces a variety of popular
                rose
                gifts, pioneering five luxurious categories: Fresh Cut Roses, Everlasting Roses, Premium Customization,
                Rose
                Home Fragrances, and Rose Jewelry. By embodying true love through creative rose gifts, our collections
                capture genuine moments of love, witnessing countless happy moments for couples.
            </div>


            <div class="cell-img-top">
                <div class="cell-img-box">
                    <img src="../../../assets/images/image5.png" alt="">
                    <div class="cell-img-text">Fresh Cut Roses</div>
                </div>

                <div class="cell-img-box">
                    <img src="../../../assets/images/image6.png" alt="">
                    <div class="cell-img-text">Everlasting Roses</div>
                </div>

                <div class="cell-img-box">
                    <img src="../../../assets/images/image7.png" alt="">
                    <div class="cell-img-text">Luxury Customization</div>
                </div>
            </div>

            <div class="cell-img-top">
                <div class="cell-img-box">
                    <img src="../../../assets/images/image8.png" alt="">
                    <div class="cell-img-text">Rose Home Fragrances</div>
                </div>
                <div class="cell-img-box">
                    <img src="../../../assets/images/image9.png" alt="">
                    <div class="cell-img-text">Rose Jewelry</div>
                </div>
            </div>


            <div class="cell-img m-20">
                <img src="../../../assets/images/images10.png" alt="">
            </div>
        </div>
    </div>




    <div class="right-cell container-m">
        <div class="cell-view">
            <div class="cell-img">
                <img src="../../../assets/images/image1.png" alt="">
            </div>
            <div class="cell-spant">
                ROSEONLY, established on January 4, 2013, a date symbolizing "loving you for a
                lifetime", was born from a simple yet profound question: Do you still believe in true love?
            </div>
            <img class="cell-left" src="../../../assets/images/image2.png" alt="">

            <div class="cell-spant">
                In an age where noisy distractions have diluted authentic emotions, and skepticism about true love
                runs rampant, loving one person for a lifetime seems like a distant myth. We chose the rose, a
                classic symbol of true love, to represent our belief. We refuse to accept that fleeting
                temporalities can overshadow the eternity of love. With the rose as our emblem, we are committed to
                the promise of "loving one person for a lifetime."
            </div>

            <div class="cell-title">ABOUT ROSEONLY</div>
            <div class="cell-img">
                <img src="../../../assets/images/image3.png" alt="">
            </div>
            <div class="cell-spant">"Love exists for those who have faith in it; love is all you need."
                <br />
                ROSEONLY, dedicated to spreading the message of love, envisions itself as a premium
                brand of true love embodied in romantic gifts, leading the way in customized luxury rose bouquets.
            </div>


            <div class="cell-img">
                <img class="cell-left" src="../../../assets/images/image11.png" alt="">
            </div>

            <div class="cell-spant">
                Since
                its inception, the brand has visited top rose gardens worldwide: from the sun-drenched Bulgarian
                rose
                gardens of the Mediterranean to Egypt along the Nile, rich in spices. From the mysterious rose
                garden at
                Hemingway's Key West home to the renowned distilled rose water region of Kashan, Iran. Finally, we
                settled on the extraordinary roses near the equator in Ecuador.
            </div>
            <div class="cell-img m-20">
                <img src="../../../assets/images/image4.png" alt="">
            </div>
            <div class="cell-title">5 GIFT CATEGORIES</div>
            <div class="cell-spant">ROSEONLY, with its unparalleled Ecuadorian roses, introduces a variety of popular
                rose
                gifts, pioneering five luxurious categories: Fresh Cut Roses, Everlasting Roses, Premium Customization,
                Rose
                Home Fragrances, and Rose Jewelry. By embodying true love through creative rose gifts, our collections
                capture genuine moments of love, witnessing countless happy moments for couples.
            </div>


            <div class="cell-img-top">
                <div class="cell-img-box">
                    <img src="../../../assets/images/image5.png" alt="">
                    <div class="cell-img-text">Fresh Cut Roses</div>
                </div>

                <div class="cell-img-box">
                    <img src="../../../assets/images/image6.png" alt="">
                    <div class="cell-img-text">Everlasting Roses</div>
                </div>

                <div class="cell-img-box">
                    <img src="../../../assets/images/image7.png" alt="">
                    <div class="cell-img-text">Luxury Customization</div>
                </div>
            </div>

            <div class="cell-img-top">
                <div class="cell-img-box">
                    <img src="../../../assets/images/image8.png" alt="">
                    <div class="cell-img-text">Rose Home Fragrances</div>
                </div>
                <div class="cell-img-box">
                    <img src="../../../assets/images/image9.png" alt="">
                    <div class="cell-img-text">Rose Jewelry</div>
                </div>
            </div>


            <div class="cell-img m-20">
                <img src="../../../assets/images/images10.png" alt="">
            </div>
        </div>
    </div>

</template>




<script name="Brand" setup>
import { onMounted } from 'vue'

onMounted(() => {
    scrollTop()
})
const scrollTop = () => {
    window.scrollTo(0, 0); // 将页面滚动到顶部
}

</script>

<style lang="scss" scoped>
.right-cell {
    font-size: 14px;

    .cell-title {
        width: 100%;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        margin: 40px 0;
    }

    .cell-view {
        width: 75%;
        margin: 20px auto;
        padding-bottom: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9d9d9;

        .cell-img {
            width: 100%;
            height: auto;
        }
    }

    .cell-spant {
        line-height: 30px;
        margin: 20px 0;
    }
}

.cell-img-view {
    display: flex;
    justify-content: space-between;

    .cell-left {
        width: 50%;
    }

    .cell-right {
        width: 50%;
        line-height: 30px;
        margin-left: 20px;
    }
}

.m-20 {
    margin: 40px 0;
}

.cell-img-top {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0;

    .cell-img-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 30%;
        height: auto;
        margin: 0 20px;

        img {
            width: 100%;
        }

        .cell-img-text {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin: 20px 0;
            font-size: 16px;
        }
    }
}


.container-m {
    .cell-img-top {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 20px 0;

        .cell-img-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 72px;
            margin: 0 20px;

            img {
                width: 100%;
                height: 72px;
            }

            .cell-img-text {
                width: 100%;
                // line-height: 20px;
                text-align: center;
                margin: 20px 0;
                font-size: 12px;
                overflow: hidden;
            }
        }
    }

}
</style>